'use client'
import CenterCard from '@/components/main/card/CenterCard'
import SCP_TitleWithLine from '@/components/main/element/TitleWithLine'
import useMeta from '@/store/meta'
import { redirect, useParams } from 'next/navigation'
import { Skeleton } from '@mui/material'
import { useEffect, useState } from 'react'
import { useLocale } from 'next-intl'

export default function CCP_CenterGroups() {
  const { centers, centerGroups } = useMeta()
  const locale = useLocale()
  const { centerGroupSlug } = useParams()
  const [isLoaded, setIsLoaded] = useState(false)
  useEffect(() => {
    if (centers && centerGroups) {
      setIsLoaded(true)
    }
  }, [centers, centerGroups])
  const currentCenterGroup = centerGroups?.find(
    (group) => group._slug === centerGroupSlug
  )
  const currentCenters = centers
    ?.filter((center) => currentCenterGroup?.centers?.includes(center._id))
    .sort((a, b) => {
      // Get the indices of a and b in currentCenterGroup.centers
      const indexA = currentCenterGroup.centers.indexOf(a._id)
      const indexB = currentCenterGroup.centers.indexOf(b._id)
      // Compare the indices to determine the order
      return indexA - indexB
    })
  let title = currentCenterGroup?.title
  let excerpt = currentCenterGroup?.excerpt
  if (!currentCenters?.length && isLoaded)
    return redirect(`${locale == 'th' ? '' : `/${locale}`}/center-clinic`)

  return (
    <div className="tw-container page-wrapper unset">
      {/* Title */}
      <div className="tw-flex tw-items-start max-md:tw-justify-center tw-space-x-16">
        {isLoaded ? (
          <SCP_TitleWithLine title={title} />
        ) : (
          <Skeleton variant="text" width={100} className="!tw-text-6xl" />
        )}
        {isLoaded ? (
          <p className="tw-max-w-xs tw-text-bgh-gray !tw-font-sarabun tw-text-sm tw-mt-2 max-md:tw-hidden">
            {excerpt}
          </p>
        ) : (
          <div className="tw-w-72 max-md:tw-hidden">
            <Skeleton variant="text" width="100%" />
            <Skeleton variant="text" width="80%" />
          </div>
        )}
      </div>
      {/* Grid */}
      <div
        className={`${!currentCenters || currentCenters?.length > 4 ? 'md:tw-grid-cols-3' : 'md:tw-grid-cols-2'} tw-grid sm:tw-grid-cols-2 tw-grid-cols-1 sm:tw-gap-x-8 sm:tw-gap-y-6 tw-gap-y-4 tw-gap-x-6 sm:tw-mt-12 tw-mt-8`}
      >
        {(currentCenters ? currentCenters : new Array(10).fill(0))?.map(
          (center, i) => (
            <CenterCard
              key={`center-card-${i}`}
              isLoading={!currentCenters}
              post={center}
              currentCenterGroup={currentCenterGroup}
              target="_blank"
            />
          )
        )}
      </div>
    </div>
  )
}
