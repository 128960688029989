import(/* webpackMode: "eager" */ "/var/www/bhq-nextjs/node_modules/@mui/material/Skeleton/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/center/CenterGroups.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/element/Breadcrumb.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/var/www/bhq-nextjs/src/components/main/element/ImageFallback.jsx");
